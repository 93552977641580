@import '../../marketplace.css';

/* Styles for topbar */
.root {
  padding: 0;
}

.topbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  align-items: center;
  justify-content: center;

  /* fill */
  background-color: var(--marketplaceColor);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportLarge) {
    height: var(--topbarHeightDesktop);
    justify-content: center;
  }
}

.home {
  display: flex;
  padding: calc((var(--topbarHeight) - var(--CheckoutPage_logoHeight)) / 2) 24px;

  @media (--viewportLarge) {
    padding: calc((var(--topbarHeightDesktop) - var(--CheckoutPage_logoHeightDesktop)) / 2) 36px;
  }
}

.logoMobile {
  display: block;

  & path {
    fill: var(--marketplaceColor);
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.logoDesktop {
  display: none;
  width: auto;
  height: 27px;

  @media (--viewportLarge) {
    display: block;
  }
}

.loading {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Styles for outer container */
.outerBox {
  /* border: solid 2px black; */
  max-width: 1230px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 150px;
  margin-top: 20px;

  @media (--viewportMobile) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.title {
  margin: 2% auto;
  color: #026786;
  font-size: 36px;
  line-height: 1;
  font-weight: 900;

  @media (--viewportMobile) {
    font-size: 28px;
    line-height: normal;
    margin-bottom: 10px;
  }
}

.outerContainer {
  /* border: solid 2px blue; */
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;

  @media (--viewportMobile) {
    flex-direction: column;
    gap: 20px;
  }
}


.errorForFailedTransaction {
  border: solid 2px #E6E6E6;
  border-radius: 4px;
  background-color: #FFFFFF;
  padding: 20px;

  @media (--viewportMobile) {
    border: solid 1px #E6E6E6;
    margin: auto;
    padding: 20px 5px;
  }
}

.errorForFailedTransaction>p:nth-child(1) {
  /* border: solid 2px red; */
  margin: 0;
  margin-bottom: 20px;
  color: #026786;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.11px;
  font-weight: 700 !important;

  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.11px;
    font-weight: 700 !important;
  }
}

.errorForFailedTransaction>p:nth-child(2) {
  border: dashed 1px #D91320;
  background-color: #FDF3F3;
  margin: 0;
  border-radius: 5px;
  padding: 10px;
}

.errorForFailedTransaction>p:nth-child(2)>p:nth-child(1) {
  /* border: solid 2px blue; */
  margin: 0;
  margin-bottom: 10px;
  color: #026786;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500 !important;

  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.11px;
  }
}

.errorForFailedTransaction>p:nth-child(2)>p:nth-child(2) {
  /* border: solid 2px green; */
  margin: 0;
  color: #4A4A4A;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500 !important;

  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.11px;
  }
}

.containerRight {
  border: solid 2px red;
  width: 40%;
  @media (--viewportMobile) {
    width: 100%;
  }
}

.containerRight,
.containerLeft {
  border: solid 2px #E6E6E6;
  border-radius: 4px;
  background-color: #FFFFFF;
  padding: 10px;
  width: 40%;

  @media (--viewportMobile) {
    border: solid 1px #E6E6E6;
    margin: auto;
    padding: 10px 5px;
    width: 100%;
  }
}

.containerRight > h3 {
  color: #026786;
}

.submitSection {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  background: #ffffff;
  z-index: 99;
}
.submitSectionInner {
  max-width: 1230px;
  margin: 0 auto;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (--viewportMobile) {
    padding: 5px 20px;
  }
}

.submitSectionPrice {
  text-align: center;
  font-size: 12px;
  line-height: normal;
  color: #b2b2b2;
}
.submitSectionPrice .totalAmount {
  text-align: center;
  display: block;
  font-size: 28px;
  line-height: normal;
  color: #00a3ad;
  font-weight: 900;
}
.submitSectionPrice button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 300px;
  font-size: 20px;
  line-height: 1;
  color: #026786;
  border-radius: 4px;
  background-color: #ffcd05;
  text-transform: capitalize;
  @media (--viewportMobile) {
    height: 48px;
    min-height: 48px;
    width: 190px;
    font-size: 18px;
  }
}
.submitSectionPrice button:hover {
  opacity: 0.85;
  background-color: #ffcd05;
}

.submitButton {
  margin: 0;
}